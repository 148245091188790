export const topBarHeight = 64
export const navbarHeight = 60
export const sideNavWidth = 260
export const sidenavCompactWidth = 80
export const containedLayoutWidth = 1200
export const AppCurrentStatus = [
    'None',
    'Applied',
    'Test Challan Uploaded',
    'Test Challan Verified',
    'Test Admit Card Issued',
    'Offer Letter Issue',
    'Offer Letter Issued',
    'Admin Challan Uploaded',
    'Admin Challan Verified',
]
