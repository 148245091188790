import useAuth from 'app/hooks/useAuth'
import { Navigate, useLocation } from 'react-router-dom'
import { flat } from 'app/utils/utils'
import routes from './../routes'

const userHasPermission = (pathname, user, pages, routes) => {
    if (!user) {
        return false
    }
    const matched = routes.find((r) => r.path === pathname)

    if (!matched) return true

    // matched && matched.auth && matched.auth.length
    //     ? matched.auth.some((x) => user.roles.includes(x))
    //     : true

    const pagePath = []
    pages.map((p) => {
        if (p.path) pagePath.push(p.path)
        if (p.children && p.children.length && p.children.length > 0) {
            const childPath = p.children.map((c) => c.path)
            pagePath.push(...childPath)
        }
        return p
    })

    // let authenticated = true

    if (matched && matched.auth) {
        if (matched.auth.includes('SA') && user.roles.includes('SA'))
            return true

        const x = pagePath.includes(matched.path)
        return x
    }

    return false
}

const AuthGuard = ({ children }) => {
    const { pathname } = useLocation()
    const { user, pages, isAuthenticated } = useAuth()
    const flatRoutes = flat(routes)

    const hasPermission = userHasPermission(pathname, user, pages, flatRoutes)
    let authenticated = isAuthenticated && hasPermission

    // // IF YOU NEED ROLE BASED AUTHENTICATION,
    // // UNCOMMENT ABOVE LINES
    // // AND COMMENT OUT BELOW authenticated VARIABLE

    if (authenticated) return <>{children}</>
    // if (isAuthenticated) return <>{children}</>

    return <Navigate replace to="/session/signin" state={{ from: pathname }} />
}

export default AuthGuard
