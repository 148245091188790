import { MatxLoading } from 'app/components'
import axiosInstance from 'axios.js'
import jwtDecode from 'jwt-decode'
import { createContext, useEffect, useReducer } from 'react'

const initialState = {
    user: null,
    pages: null,
    isInitialised: false,
    isAuthenticated: false,
}

const isValidToken = (accessToken) => {
    if (!accessToken) return false

    const decodedToken = jwtDecode(accessToken)
    const currentTime = Date.now() / 1000
    return decodedToken.exp > currentTime
}

const setSession = (accessToken, user, pages) => {
    if (accessToken) {
        localStorage.setItem('accessToken', accessToken)
        localStorage.setItem('user', JSON.stringify(user))
        localStorage.setItem('pages', JSON.stringify(pages))
        axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`
    } else {
        localStorage.removeItem('accessToken')
        localStorage.removeItem('user')
        localStorage.removeItem('pages')
        delete axiosInstance.defaults.headers.common.Authorization
    }
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'INIT': {
            const { isAuthenticated, user, pages } = action.payload
            return {
                ...state,
                user,
                pages,
                isAuthenticated,
                isInitialised: true,
            }
        }
        case 'LOGIN': {
            const { user, pages } = action.payload
            return { ...state, user, pages, isAuthenticated: true }
        }
        case 'LOGOUT': {
            return {
                ...state,
                isAuthenticated: false,
                user: null,
                pages: null,
            }
        }
        case 'REGISTER': {
            const { user, pages } = action.payload
            return { ...state, isAuthenticated: true, user, pages }
        }
        default: {
            return state
        }
    }
}

const AuthContext = createContext({
    ...initialState,
    method: 'JWT',
    logout: () => {},
    login: () => Promise.resolve(),
    register: () => Promise.resolve(),
})

export const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)

    const login = async (email, password) => {
        const response = await axiosInstance.post('/register/login-admin', {
            id: email,
            pin: password,
        })

        const { user, pages, roles, status, token, permissions } = response.data

        if (!status) {
            return false
        }

        let userUpdate = { ...user, roles, permissions }

        const pagesUpdate = pages.map((p) =>
            p.children && p.children.length > 0 ? p : { ...p, children: null }
        )

        setSession(token, userUpdate, pagesUpdate)
        dispatch({
            type: 'LOGIN',
            payload: { user: userUpdate, pages: pagesUpdate },
        })

        return true
    }

    const register = async (email, username, password) => {
        const { data } = await axiosInstance.post('/api/auth/register', {
            email,
            username,
            password,
        })

        const { accessToken, user } = data
        setSession(accessToken)
        dispatch({ type: 'REGISTER', payload: { user } })
    }

    const logout = () => {
        setSession(null)
        dispatch({ type: 'LOGOUT' })
    }

    useEffect(() => {
        ;(async () => {
            try {
                const accessToken = window.localStorage.getItem('accessToken')

                if (accessToken && isValidToken(accessToken)) {
                    const user = JSON.parse(window.localStorage.getItem('user'))
                    const pages = JSON.parse(
                        window.localStorage.getItem('pages')
                    )

                    axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`

                    dispatch({
                        type: 'INIT',
                        payload: { isAuthenticated: true, user, pages },
                    })
                } else {
                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: false,
                            user: null,
                            pages: null,
                        },
                    })
                }
            } catch (err) {
                dispatch({
                    type: 'INIT',
                    payload: {
                        isAuthenticated: false,
                        user: null,
                        pages: null,
                    },
                })
            }
        })()
    }, [])

    if (!state.isInitialised) {
        return <MatxLoading />
    }

    return (
        <AuthContext.Provider
            value={{ ...state, method: 'JWT', login, logout, register }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext
