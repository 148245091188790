import axios from 'axios'
const apiEndPoint = process.env.REACT_APP_Api_Endpoint

const axiosInstance = axios.create({
    baseURL: apiEndPoint,
})

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) =>
        Promise.reject(
            (error.response && error.response.data) || 'Something went wrong!'
        )
)

export default axiosInstance
