import AuthGuard from "./auth/AuthGuard";
import Redirect from "./auth/Redirect";
import MatxLayout from "./components/MatxLayout/MatxLayout";
import dashboardRoutes from "./views/dashboard/DashboardRoutes";
import NotFound from "./views/sessions/NotFound";
import sessionRoutes from "./views/sessions/SessionRoutes";

const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [...dashboardRoutes],
  },
  ...sessionRoutes,
  { path: "/", element: <Redirect /> },
  { path: "*", element: <NotFound /> },
];

export default routes;
