import { authRoles } from 'app/auth/authRoles'
import Loadable from 'app/components/Loadable'
import { lazy } from 'react'

const Default = Loadable(lazy(() => import('./Default')))
const Settings = Loadable(lazy(() => import('../setup/Settings')))

const RegistrationList = Loadable(
    lazy(() => import('../forms/Registration-List'))
)

const Roles = Loadable(lazy(() => import('./../user-role/Roles')))
const Students = Loadable(lazy(() => import('./../forms/Students')))

const dashboardRoutes = [
    { path: '/dashboard/default', element: <Default />, auth: authRoles.admin },
    // Setup Menu

    {
        path: '/setup/settings',
        element: <Settings />,
        auth: authRoles.admin,
    },

    // Setup menu end
    // Forms Menu
    {
        path: '/forms/total-registrations',
        element: <RegistrationList />,
        auth: authRoles.admin,
    },

    // UserRoles
    {
        path: '/user-role/roles',
        element: <Roles />,
        auth: authRoles.admin,
    },
    {
        path: '/student-marks',
        element: <Students />,
        auth: authRoles.admin,
    },
]

export default dashboardRoutes
