import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AuthProvider } from 'app/contexts/JWTAuthContext'
import { SettingsProvider } from 'app/contexts/SettingsContext'
import { Provider } from 'react-redux'
import { useRoutes } from 'react-router-dom'
import { MatxTheme } from './components'
import store from './redux/store'
import routes from './routes'
import enGB from 'date-fns/locale/en-GB'

const App = () => {
    const content = useRoutes(routes)

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
            <Provider store={store}>
                <SettingsProvider>
                    <MatxTheme>
                        <AuthProvider>{content}</AuthProvider>
                    </MatxTheme>
                </SettingsProvider>
            </Provider>
        </LocalizationProvider>
    )
}

export default App
